import { useMemo } from "react";
import "./scss/WheelOfPrizes.scss";
import Lottie from 'react-lottie-player'
import confetti from '../animations/confetti.json'
import confettiSecondary from '../animations/confettiSecondary.json'
import Color from 'color';
import { getColors, replaceColor } from 'lottie-colorify';
import chest from '../animations/chest.json'
import happyEmoji from '../animations/happyEmoji.json'
import SubmitButton from "./SubmitButton";

function getRandomInt(max) {
	return Math.floor(Math.random() * max);
}

function randomLighten(color){
	const ligthenValues = [0.1, 0.15, 0.2]
	return Color.rgb(color).lighten(ligthenValues[getRandomInt(3)]).hex()
}

function WheelOfPrizesWon({ next, data, styling, button }) {

	// let uniqueColors = useMemo(() => {
	// 	const colors = getColors(confetti)
	// 	const colorMap = {}
	// 	colors.forEach( color => {
	// 		const colorKey = "" + color[0] + "," + color[1] + "," + color[2]
	// 		if(colorMap[colorKey]) colorMap[colorKey]++
	// 		else colorMap[colorKey] = 1
	// 	})
	// 	console.log("colorMap", colorMap)
	// 	return Object.keys(colorMap)
	// }, [])

	const winAnimationColors = useMemo(() => {
		const colors = styling.winAnimationColors?.filter(color => color != null)
		if(colors?.length) return colors
		else return ["#ff3b30", "#2aac7e", "#236eff"]
	}, [styling.winAnimationColors])

	console.log("data.winAnimationColors", styling.winAnimationColors)
	console.log("winAnimationColors", winAnimationColors)

	let customConfetti = useMemo(() => {
		let customConfetti = replaceColor([13,13,128], randomLighten(winAnimationColors[0]), confetti)
		customConfetti = replaceColor([42,172,126], randomLighten(winAnimationColors[1 % winAnimationColors.length]), customConfetti)
		customConfetti = replaceColor([255,59,48], randomLighten(winAnimationColors[2 % winAnimationColors.length]), customConfetti)
		customConfetti = replaceColor([255,149,0], randomLighten(winAnimationColors[3 % winAnimationColors.length]), customConfetti)
		return customConfetti
	}, [])

	let customConfettiSecondary = useMemo(() => {
		let  customConfetti = replaceColor([0, 75, 221], randomLighten(winAnimationColors[4 % winAnimationColors.length]), confettiSecondary)
		customConfetti = replaceColor([35,110,255], randomLighten(winAnimationColors[5 % winAnimationColors.length]), customConfetti)
		customConfetti = replaceColor([122, 165, 255], randomLighten(winAnimationColors[6 % winAnimationColors.length]), customConfetti)
		customConfetti = replaceColor([188,209,255], randomLighten(winAnimationColors[7 % winAnimationColors.length]), customConfetti)
		customConfetti = replaceColor([235,241,255], randomLighten(winAnimationColors[8 % winAnimationColors.length]), customConfetti)
		return customConfetti
	}, [])

	return (
		<>
			<div style={{ marginTop: styling?.marginTop }} className="wheel_ofprizes-won">
				<div className="wheel__ofprizes-wonContent">
					<div className="wheel__ofprizes-chest mtop--20">
						<div dangerouslySetInnerHTML={{ __html: styling?.winMessage || 'Congratulations you won' }}></div>
						{styling.showPrizeImage &&
							<img className="wheel__ofprizes-chestPrize" src={data?.prize?.image} />
						}
						<h4 style={{ fontSize: '2em' }}>{data?.prize?.text}</h4>
						<div>{data?.prize?.description}</div>
						{/* <div className="wheel__ofprizes-wonBanner mtop--20">
							<div className="wheel__ofprizes-wonBannerLeft">
								<Lottie
									loop
									animationData={happyEmoji}
									play
									style={{ width: 50, height: 50 }}
								/>
							</div>
							<div className="wheel__ofprizes-wonBannerRight">
								YOU WON
							</div>
						</div> */}
					</div>
				</div>
				<div className="wheel__ofprizes-wonConfetti">
					<div className="wheel__ofprizes-wonConfettiA">
						<Lottie
							loop
							animationData={customConfettiSecondary}
							play
							style={{ width: '100%', height: '100%' }}
						/>
					</div>
					<div>
					<Lottie
						loop
						animationData={customConfetti}
						play
						style={{ width: '100%', height: '100%' }}
					/>
					</div>
					
				</div>
			</div>
			<div className="text--center" style={{ position: 'fixed', bottom: '64px', left: 0, right: 0 }} >
				<SubmitButton
					text={data?.nextButtonText || "Next"}
					size={button?.submitSize}
					onSubmit={next}
					buttonStyle={{borderRadius:button?.borderRadius, background: data?.buttonColor, color: data?.textColor}}
				/>
			</div>
		</>
	);
};
export default WheelOfPrizesWon;
