import { useState, useEffect } from "react";
import CampaignPage from './CampaignPage.jsx'
import Loader from './Loader.jsx'
import {getCampaign, submitPage, getLiveControl} from './campaignActions.js'
import "./scss/main.scss";
import TagManager from 'react-gtm-module';
import {useLogger} from './utils/useLogger.jsx';
import LockTime from './utils/LockTime.jsx';
import {showsCorrectAnswerEndPage, showsCorrectAnswer, getCorrectAnswers, pageToSkip, pickPages, mustShufflePages, mustRemoveLosingSlice, hasLiveControl, CAMPAIGN2_INFO_PAGE} from './utils/custom.mjs';
import 'animate.css';

const findScratchAndRegistration = function (campaign){
	let scratch, registration;
	for(let i=0; i<campaign.pages.length; i++){
		const page = campaign.pages[i];
		for(let j=0; j<page.elements.length; j++){
			if(page.elements[j].type==="scratch"){
				scratch = {page:page, element:page.elements[j]};
			}
			else if(page.elements[j].type==="registrationForm"){
				registration = {page:page, element:page.elements[j]};
			}
		}
	}
	return {scratch, registration};
}

function App() {
	const pathname = window.location.pathname;
	const campaignId = pathname.split('/')[1];
	const [campaignData, setCampaignData] = useState(undefined);
	const [currentPageId, setCurrentPageId] = useState(undefined);
	const [loadingStatus, setLoadingStatus] = useState('loading');
	const [elementsErrors, setElementsErrors] = useState('loading');
	const [unexpectedError, setUnexpectedError] = useState(undefined);
	const [currentPage, setCurrentPage] = useState(null);
	const [sending, setSending] = useState(false);
	const [result, setResult] = useState(null);
	const [correctAnswer, setCorrectAnswer] = useState(null);
	const [responses, setResponses] = useState(null);
	const [lockTime, setLockTime] = useState(null);
	const [infoPages, setInfoPages] = useState(null);
	const [addLog, setLoggerUserId, startLogCollector, setLoggerPageId] = useLogger(campaignId);
	/* Start the log collector */
	startLogCollector();

	useEffect(()=>{
		let unsub;
		if(campaignId && hasLiveControl(campaignId)) {
			unsub=getLiveControl(campaignId, (data)=>{
				setInfoPages({elapsedPageId:data.elapsedPageId, participatedPageId:data.participatedPageId, startPageId:data.startPageId});
				setLockTime(data?.lockTime);
				const pageId = data?.pageId;
				if(responses&&responses[pageId]){
					setCurrentPageId(data?.participatedPageId);
				}else{
					if(data?.timedOut) setCurrentPageId(data?.elapsedPageId);
					else setCurrentPageId(pageId);
				}
			});
		}
		return ()=>{
			if(unsub) unsub();
		}
	}, [campaignId, responses]);

	useEffect(() => {
		let mounted = true;
		getCampaign(campaignId)
		.then(async (data)=>{
			if(mounted){
				if(data===undefined){
					setCampaignData(undefined);
					setCurrentPageId(undefined);
				}else{
					if(mustShufflePages(campaignId)){
						console.log('@data', data.campaign.pages);
						console.log('BEFORE@', JSON.parse(JSON.stringify(data.campaign.pages)));
						pickPages(campaignId, data.campaign.pages);
						console.log('AFTER@', JSON.parse(JSON.stringify(data.campaign.pages)));
					}
					if(mustRemoveLosingSlice(campaignId)){
						data.campaign.pages.forEach(({elements})=>{
							elements.forEach(element=>{
								if(element.type==="wheelOfPrizes"){
									element.slices = element.slices.filter((slice)=>slice.prizes.length>0);
								}
							});
						});
					}
					const {campaign, responses, user_id} = data;

					if(hasLiveControl(campaignId)) setResponses(responses);

					const toSkip = pageToSkip(campaignId, responses);
					if(toSkip){
						if(responses) responses[toSkip] = {elements:{}};
					}

					await setLoggerUserId(user_id);
					if (campaign?.googleTagId){
						console.log(campaign.googleTagId);
						TagManager.initialize({ gtmId: campaign.googleTagId.replace(/\s+/g, '') });
					}
					console.log('campaign', campaign);
					if(showsCorrectAnswer(campaignId)){
						pickPages(campaignId, campaign.pages);
					}
					if(showsCorrectAnswerEndPage(campaignId) && responses){
						localStorage.setItem('_as', JSON.stringify(responses));
					}
					if(['VrBlilSIOdPOVt9A2lu4','HUy1Tu7yiW0s8GN6KS0T','0vgd2wTpZcPcppQ6PTVy','Z5R4zwTMGAA7M0504Ebg','QtE4YvOX3iGlQe4J6UJL'].includes(campaignId) && responses){
						const {scratch, registration} = findScratchAndRegistration(campaign);
						if(scratch && registration){
							const prize = responses[scratch.page.id]?.elements?.[scratch.element.id]?.value;
							if(!prize){
								responses[registration.page.id]= {elements:{[registration.element.id]:null}}
							}
						}
					}
					setCampaignData(campaign);
					const pg = campaign.pages.find(page => (!responses?.[page.id] || page.elements.find( el => el.type === "ugc")));
					if(pg && !hasLiveControl(campaignId)){
						setCurrentPageId(pg.id);
						await setLoggerPageId(pg.id);
						addLog('action', null, 'Page & data loaded successfully')
					}
				}
				setLoadingStatus('loaded');
			}
		})
		.catch((error)=>{
			console.error('Failed to load', error)
			addLog('error', null, `Failed to load page & data: ${error}`)
		});
		return () =>{
			mounted = true;
		};
	}, [campaignId]);
	async function onPageSubmit(pageId, data, autoNext=true){
		if(sending) return;
		data=data?data:{};
		console.log('Sending page:', pageId);
		console.table('data',data);
		if(showsCorrectAnswerEndPage(campaignId)){
			const o = JSON.parse(localStorage.getItem('_as')||'{}');
			o[pageId] = {elements:{}}
			for(const elementId in data){
				if(data[elementId]?.selected) o[pageId].elements[elementId] = {value:data[elementId].selected};
			}
			localStorage.setItem('_as', JSON.stringify(o));
		}
		addLog('action', null, `Page submit with data: ${JSON.stringify(data)}`);

		setSending(true);
		setUnexpectedError(null);
		const elements = [];
		for (const id in data) {
			const value = data[id];
			elements.push({id, value})
		}

		try{
			const result = await submitPage(campaignId, pageId, {elements})
			console.log('vote result:', result)
			addLog('action', null, `Vote result: ${JSON.stringify(result)}`)
			setResult(result);
			if(hasLiveControl(campaignId)) setResponses({...responses, ...result?.responses});
			setSending(false);

			if(showsCorrectAnswer(campaignId)){
				const correctAnswers = getCorrectAnswers(campaignId);
				const fp = campaignData.pages.find(p=>p.id===pageId);
				if(fp) {
					const fe = fp.elements.find(e=>e.type==='poll');
					if(fe){
						const fc = fe.options.find(o=>correctAnswers.includes(o.id));
						if(fc) {
							setTimeout(()=>{
								goToNextPage();
							}, 2000);
							setCorrectAnswer(fc.id);
							return true;
						}
					}
				}
			}
			if(autoNext) goToNextPage();
			return true;

		} catch (error) {

			let userFriendlyErrorMessage = '';
			if( error.code === 'functions/invalid-argument' && !error?.details?.elementsErrors ){
				userFriendlyErrorMessage = 'Unexpected error: '+ error.message;
			}
			else if( error.code === 'functions/internal' ){
				if(error.details){
					userFriendlyErrorMessage = 'Unexpected error: '+ error.message;
				}else{
					const onlineStatus = window?.navigator?.onLine;
					if(onlineStatus===false){
						userFriendlyErrorMessage = 'Could not submit, make sure you are connected to the internet and try again.';
					}else{
						userFriendlyErrorMessage = 'Could not submit, try again later.';
					}
				}
			}
			else if( error.code === 'functions/permission-denied' ){
				userFriendlyErrorMessage = 'Could not submit, try refreshing the page.';
			}
			setSending(false);
			setUnexpectedError(userFriendlyErrorMessage);
			console.error(error, error.code, error.details)
			if(error?.details?.elementsErrors){
				setElementsErrors(error.details.elementsErrors);
				addLog('error', null, `Unexpected Error: ${JSON.stringify(userFriendlyErrorMessage)}, Details: ${JSON.stringify(error.details.elementsErrors)}`)
			}else{
				addLog('error', null, `Unexpected Error: ${JSON.stringify(userFriendlyErrorMessage)}, Details: Not provided`)
			}
			return false;
		}
	}
	const goToNextPage = () => {
		if(hasLiveControl(campaignId)) return false;
		setCorrectAnswer(null);
		let index = campaignData?.pages.findIndex((element)=>element.id===currentPageId);

		let _inc = 1;
		if(pageToSkip(campaignId, JSON.parse(localStorage.getItem('_as')||'{}')) && currentPageId===CAMPAIGN2_INFO_PAGE) _inc=2;

		if((index+_inc)<campaignData?.pages.length){
			if(['VrBlilSIOdPOVt9A2lu4','HUy1Tu7yiW0s8GN6KS0T','0vgd2wTpZcPcppQ6PTVy','Z5R4zwTMGAA7M0504Ebg','QtE4YvOX3iGlQe4J6UJL'].includes(campaignId) && result){
				const {scratch, registration} = findScratchAndRegistration(campaignData);
				if(scratch && registration && campaignData.pages[index]===scratch.page){
					const prize = result.responses[scratch.page.id]?.elements?.[scratch.element.id]?.value;
					if(!prize) index++;
				}
			}
			setUnexpectedError(null);
			const nextPageId = campaignData.pages[index + _inc].id;
			setCurrentPageId(nextPageId);
			addLog('action', null, `Going to next page: ${nextPageId}`)
		}else{
			addLog('error', null, `Error: Went outside pages list`)
			console.error('TBI: handle the user going outside the pages list. This should never happen');
		}
	}
	const hasSendingLoader = campaignData?.type !== "scratch";
	useEffect(() => {
		if(currentPageId && campaignData){
			setLoggerPageId(currentPageId);
			setCurrentPage(campaignData?.pages.find(page=>page.id === currentPageId));
			if(!campaignData.hasOwnProperty('endDate')) return;
			if(!campaignData.endDate) return;
			if( Date.now() > campaignData.endDate){
				if(campaignData.expiredPage) setCurrentPage(campaignData.expiredPage)
			}
		}
	}, [currentPageId, campaignData])
	return (
		<>
			<div className="App page">
				{(loadingStatus==='loading') && <Loader background={campaignData?.tint?.background} />}
				{(loadingStatus === 'loaded') && currentPage && lockTime && <LockTime lockTime={lockTime} style={{"position":"absolute", "top":0, "left":0, "zIndex":2}}/>}
				{(loadingStatus === 'loaded') && currentPage && <CampaignPage
					campaignId={campaignId}
					key={currentPage.id}
					campaign={campaignData}
					data={currentPage}
					sending={sending}
					elementsErrors={elementsErrors}
					onPageSubmit={onPageSubmit}
					goToNextPage={goToNextPage}
					result={result}
					correctAnswer={correctAnswer}
					hideSubmitButton={hasLiveControl(campaignId) && ((currentPage.id===infoPages?.elapsedPageId)||(currentPage.id===infoPages?.participatedPageId)||(currentPage.id===infoPages?.startPageId))}
				/>}
				{(loadingStatus==='loaded') && currentPage===undefined && <center>Campaign not found</center>}
				{unexpectedError && <div className="page__error">{unexpectedError}</div>}
				{hasSendingLoader && sending &&
					<div className="footer__loader animate__animated animate__slideInUp">
						<Loader background={null} fullScreen={false} />
						<h4>Please be patient...</h4>
					</div>
				}
			</div>
		</>
	);
}

export default App;