import { useEffect, useState } from "react";

function LockTime({ lockTime, style }) {
	const [totalTime, setTotalTime] = useState(0);
	const [remainingTime, setRemainingTime] = useState(totalTime);

	useEffect(() => {
		setTotalTime(() => Math.max(lockTime - Date.now(), 0))
	}, [lockTime]);
	useEffect(() => {
		const intervalId = setInterval(() => {
			const timeLeft = lockTime - Date.now();
			setRemainingTime(Math.max(timeLeft, 0));
			if (timeLeft <= 0) clearInterval(intervalId);
		}, 150);
		return () => clearInterval(intervalId);
	}, [lockTime]);

	// Calculate the percentage of time remaining relative to the initial time
	let percentage = totalTime > 0 ? (remainingTime / totalTime) * 100 : 0;
	if (percentage < 0) {
		percentage = 0;
	} else if (percentage > 100) {
		percentage = 100;
	}

	const hue = (120 * percentage) / 100;
	const barColor = `hsl(${hue}, 60%, 50%)`;

	return (
		<div style={{ width: "100%", height: "10px", backgroundColor: "#ccc", ...style }}>
			<div style={{height: "100%", overflow: "hidden", position: "relative"}}>
				<div style={{height: "100%", width: `${percentage}%`, backgroundColor: barColor, transition: "width 0.15s linear, background-color 0.15s linear"}}></div>
			</div>
		</div>
	);
}

export default LockTime;
