import "./scss/WheelOfPrizes.scss";
import { useEffect, useState, useMemo, useCallback, lazy, Suspense } from "react";
import WheelOfPrizesWon from "./WheelOfPrizesWon";
import WheelOfPrizesLost from "./WheelOfPrizesLost";
import SubmitButton from "./SubmitButton";
const PhaserWheel = lazy(() => import('../common/PhaserWheel'));
function WheelOfPrizesPreview({ campaign, data, value, sending, elementErrors, onChange, onSubmit, goToNextPage, result, primaryTextColor, button }) {
	console.log('~WheelOfPrizes', data.id, value, elementErrors)
	const [screen, setScreen] = useState(null);
	const [spinning, setSpinning] = useState(false);
	const [loading] = useState(false);
	const [mappedData, setMappedData] = useState(null);
	const [sliceIndex, setSliceIndex] = useState(null);
	const [spinResult, setSpinResult] = useState(null);
	const [realWheel, setRealWheel] = useState(null);
	const [isWheelReady, setIsWheelReady] = useState(false);

	const shouldApplyWheelStyling = () => {
		return data.image
	}

	const defaultColors = [
		"#0466c8",
		"#023e7d",
		"#001845",
		"#33415c",
		"#5bba6f",
		"#137547",
		"#fbb13c",
		"#ffd97d",
		"#a4133c",
		"#ff758f",
		"#3a0ca3",
		"#3f37c9",
		"#4895ef"
	];

	const textColors = useMemo(() => {
		return realWheel?.slices.reduce((acc, slice) => [...acc, ...[slice.textColor || '#fff']], [])
	}, [realWheel]);

	const spin = useCallback(() => {
		onSubmit(null, false);
	}, [onSubmit])
	const setData = useCallback((data) => {
		setSpinning(false);
		if (spinResult['won']) {
			setScreen('won')
		} else {
			setScreen('lost')
		}
	}, [spinResult])
	const borderImageStyling = useMemo(() => {
		if(!shouldApplyWheelStyling) return {};

		return {
			backgroundImage: `url(${realWheel?.borderImage || '/images/common/border.png'})`,
			backgroundSize: `${realWheel?.borderImageSize || 130}%`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '50% center'
		}
	}, [realWheel])
	useEffect(() => {
		const { wheelOfPrizeId } = data;
		if (!wheelOfPrizeId) return;
		const foundWheel = campaign.pages.reduce((result, page) => {
			if (!result) {
				const element = page.elements.find((element) => element.id === wheelOfPrizeId);
				if (element) {
					return element;
				}
			}
			return result;
		}, null);
		if (!foundWheel) return;
		setRealWheel(foundWheel);

		const d = foundWheel?.slices.map((slice, idx) => {
			let o = {
				option: slice.text,
				style: {
					backgroundColor: slice.color || defaultColors[idx],
					textShadowColor: slice.textShadowColor
				}
			}
			if (slice.image && slice.image !== '/images/common/placeholder.png') {
				o['image'] = { uri: slice.image, sizeMultiplier: slice.imageSizeMultiplier};
			}
			return o
		})
		setMappedData(d);
		onChange(data.id, true);
	}, [data])
	useEffect(() => {
		if (!result || !mappedData) return;
		
		setSpinResult({
			won: false,
			sliceIndex: mappedData.length - 1
		});
		setSliceIndex(parseInt(mappedData.length - 1));
		setTimeout(() => {
			setSpinning(true);
		}, 150);
	}, [result, mappedData])
	const spinDuration = Number.isFinite(realWheel?.spinDuration)?realWheel?.spinDuration:6;
	return (
		<div style={{ marginTop: data?.marginTop }} className="wheel_ofprizes">
			{screen === null &&
				<>
					{mappedData &&
					<Suspense>
						<div className="wheel__inner">
							<div className={`wheel__inner-wheel ${ shouldApplyWheelStyling && 'wheel__inner-wheelBordered'}`} style={borderImageStyling}>
								{realWheel.centerImage && <img src={realWheel.centerImage} className="wheel__inner-wheelNovibet" alt="logo"/> }
									<PhaserWheel 
										mustStartSpinning={spinning}
										prizeNumber={sliceIndex}
										data={mappedData}
										borderImage={data.borderImage}
										centerImage={data.centerImage}
										centerImageSize={data.centerImageSize}
										pinImage={data.pinImage}
										pinImageSize={data.pinImageSize}
										backgroundColors={[defaultColors[0], defaultColors[1]]}
										buttonColor={data.buttonColor}
										textColors={textColors}
										borderStyle={data.borderStyle}
										borderColor={data.borderColor}
										borderWidth={data.borderWidth}
										onStopSpinning={setData}
										onCreate={() => setIsWheelReady(true)}
										spinDuration={spinDuration}
										startingOptionIndex={0}
										disableInitialAnimation={true}
										innerRadius={10}
										perpendicularText={data.perpendicularText || false}
									/>
							</div>
						</div>

						<div className="text--center mtop--20">
							{(!loading && !spinning && isWheelReady) &&
								<SubmitButton
									text={realWheel.buttonText || "Spin now"}
									size={button?.submitSize}
									onSubmit={spin}
									containerStyle={{ marginTop: "15px" }}
									buttonStyle={{ borderRadius: button?.borderRadius, background: realWheel.buttonColor, color: realWheel.textColor }}
								/>
							}

						</div>
					</Suspense>
					}
					{(!isWheelReady || loading) &&
						<div className="text--center mtop--20">
							<div className="waveform">
								<div className="waveform__bar"></div>
								<div className="waveform__bar"></div>
								<div className="waveform__bar"></div>
								<div className="waveform__bar"></div>
							</div>
						</div>
					}
				</>
			}
			{screen === 'won' && <WheelOfPrizesWon next={goToNextPage} styling={realWheel} data={spinResult} button={button}/>}
			{screen === 'lost' && <WheelOfPrizesLost next={goToNextPage} styling={realWheel} data={realWheel} primaryTextColor={primaryTextColor} button={button}/>}
		</div>
	);
};
export default WheelOfPrizesPreview;
