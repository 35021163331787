import { useEffect, useState, useCallback, useMemo, lazy, Suspense } from "react";
import "./scss/WheelOfPrizes.scss";
import WheelOfPrizesWon from "./WheelOfPrizesWon";
import WheelOfPrizesLost from "./WheelOfPrizesLost";
import SubmitButton from "./SubmitButton";
import novibetImage from './novibet.png';

const PhaserWheel = lazy(() => import('../common/PhaserWheel'));


function WheelOfPrizes({ data, value, sending, elementErrors, onChange, onSubmit, goToNextPage, result, primaryTextColor, button }) {
	// console.log('~WheelOfPrizes', data.id, value, elementErrors)
	const pathname = window.location.pathname;
	const campaignId = pathname.split('/')[1];
	const [screen, setScreen] = useState(null);
	const [spinning, setSpinning] = useState(false);
	const [loading, setLoading] = useState(false);
	const [mappedData, setMappedData] = useState(null);
	const [sliceIndex, setSliceIndex] = useState(null);
	const [spinResult, setSpinResult] = useState(null);
	const [isWheelReady, setIsWheelReady] = useState(false);

	const isNovibet = useMemo(() => {
		return ['pxYhIDJ3nAZ0ppKCZLce', 'CkYDNrwjZPJqs2DjCzNP', 'Focaphg4gEUycfwgvoXQ'].includes(campaignId);
	}, [campaignId])
	const shouldApplyWheelStyling = () => {
		return data.image || isNovibet
	}

	const defaultColors = [
		"#0466c8",
		"#023e7d",
		"#001845",
		"#33415c",
		"#5bba6f",
		"#137547",
		"#fbb13c",
		"#ffd97d",
		"#a4133c",
		"#ff758f",
		"#3a0ca3",
		"#3f37c9",
		"#4895ef"
	];

	const textColors = useMemo(() => {
		return data?.slices.reduce((acc, slice) => [...acc, ...[slice.textColor || '#fff']], [])
	}, [data]);

	const spin = useCallback(() => {
		setLoading(true);
		onSubmit(null, false);
	}, [onSubmit])

	const setData = useCallback((data) => {
		setSpinning(false);
		if (spinResult['won']) {
			setScreen('won')
		} else {
			setScreen('lost')
		}
	}, [spinResult])

	const borderImageStyling = useMemo(() => {
		if (!shouldApplyWheelStyling) return {};

		return {
			// backgroundImage: `url(${data.borderImage || '/images/common/border.png'})`,
			backgroundSize: `${data.borderImageSize || 130}%`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '50% center'
		}
	}, [data])

	useEffect(() => {
		const d = data?.slices.map((slice, idx) => {
			let o = {
				option: slice.text,
				style: {
					backgroundColor: slice.color || defaultColors[idx],
					textShadowColor: slice.textShadowColor
				},
			}
			if (slice.image && slice.image !== '/images/common/placeholder.png') {
				o['image'] = { uri: slice.image, sizeMultiplier: slice.imageSizeMultiplier };
			}
			return o
		})
		setMappedData(d);
		onChange(data.id, true);
	}, [data])

	useEffect(() => {
		if (!result) return;
		Object.entries(result['responses']).forEach(([key, value]) => {
			Object.entries(value['elements']).forEach(([elKey, elValue]) => {
				if (!elValue['spinResult']) return;
				setSpinResult(elValue['spinResult']);
				setSliceIndex(parseInt(elValue['spinResult']['sliceIndex']));
				setTimeout(() => {
					setLoading(false);
					setSpinning(true);
				}, 150);
			})
		})
	}, [result])
	const spinDuration = Number.isFinite(data.spinDuration) ? data.spinDuration : 6;
	console.log("mappedData", mappedData)
	return (
		<div style={{ marginTop: data?.marginTop }} className="wheel_ofprizes">
			{screen === null &&
				<>
					{mappedData &&
						<Suspense>
							<>
								<div className="wheel__inner">
									<div className={`wheel__inner-wheel ${shouldApplyWheelStyling && 'wheel__inner-wheelBordered'}`} style={borderImageStyling}>
										{/* {data.centerImage && <img src={data.centerImage} className="wheel__inner-wheelNovibet"/> } */}
										{isNovibet && <img src={novibetImage} className="wheel__inner-wheelNovibet" />}

										<PhaserWheel
											mustStartSpinning={spinning}
											prizeNumber={sliceIndex}
											data={mappedData}
											borderImage={data.borderImage}
											centerImage={data.centerImage}
											centerImageSize={data.centerImageSize}
											pinImage={data.pinImage}
											pinImageSize={data.pinImageSize}
											backgroundColors={[defaultColors[0], defaultColors[1]]}
											buttonColor={data.buttonColor}
											textColors={textColors}
											borderStyle={data.borderStyle}
											borderColor={data.borderColor}
											borderWidth={data.borderWidth}
											onStopSpinning={setData}
											onCreate={() => setIsWheelReady(true)}
											spinDuration={spinDuration}
											startingOptionIndex={0}
											disableInitialAnimation={true}
											innerRadius={10}
											perpendicularText={data.perpendicularText || false}
										/>
									</div>
								</div>

								<div className="text--center mtop--20">
									{(!loading && !spinning && isWheelReady) &&
										<SubmitButton
											text={data.buttonText || "Spin now"}
											size={button?.submitSize}
											onSubmit={spin}
											containerStyle={{ marginTop: "15px" }}
											buttonStyle={{ borderRadius: button?.borderRadius, background: data.buttonColor, color: data.textColor }}
										/>
									}

								</div>
							</>
						</Suspense>
					}
					{(!isWheelReady || loading) &&
						<div className="text--center mtop--20">
							<div className="waveform">
								<div className="waveform__bar"></div>
								<div className="waveform__bar"></div>
								<div className="waveform__bar"></div>
								<div className="waveform__bar"></div>
							</div>
						</div>
					}

				</>
			}
			{screen === 'won' && <WheelOfPrizesWon next={goToNextPage} styling={data} data={spinResult} button={button} />}
			{screen === 'lost' && <WheelOfPrizesLost next={goToNextPage} styling={data} data={data} primaryTextColor={primaryTextColor} button={button} />}
		</div>
	);
};
export default WheelOfPrizes;
